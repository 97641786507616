import { memo, useState, useRef } from 'react'
import QRCode from "react-qr-code"
import { useDisclosure, Box, Button, Flex, Icon, Input, Text, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter, ModalBody } from '@chakra-ui/react'
import Login from '../components/Login/Login.js'
import PingResponseBar from '../components/PingResponseBar/PingResponseBar'
import { getStatusByTicket, getLastTicketsStatus, getPaymentLink } from '../services/paymentCalls.js'
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons'
import PrintComponent from "../components/PrintComponent/PrintComponent"
import { useReactToPrint } from 'react-to-print';


const Dashboard = memo(() => {
	const componentRef = useRef();
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [ticket, setTicket] = useState(null)
	const [ticketStatus, setTicketStatus] = useState(undefined)
	const [showError, setShowError] = useState()
	const [selectedStatus, setSelectedStatus] = useState()
	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleLogin = (isUserLoggedIn) => {
			setIsLoggedIn(isUserLoggedIn)
	}

	const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

	const onSelectTicket = (selectedTicket) => {
		setSelectedStatus(selectedTicket)
		onOpen()
	}

	const onCancelPrint = () => {
		setSelectedStatus(null)
		onClose()
	}

	const onHandlePrint = () => {
		handlePrint()
		onClose()
	}

	const handleGenrateReport = () => {
		try {
			getStatusByTicket(ticket).then(res => {
				if(res) {
					setTicketStatus(res)
				} else {
					setShowError(true)
					errorTimer()
				}
			})
		} catch (error) {
			console.log("🚀 ~ file: Dashboard.js:28 ~ handleGenrateReport ~ error:", error)
		}
	}

	const showLastTickets = (num) => {
		setTicket(null);
		try {
			getLastTicketsStatus(num).then(async res => {
				if(res) {
					res.map(async (_ticket) => {
						if (!_ticket.paymentReceived) {
							const paymentLink = await getPaymentLink(_ticket.num_ticket, _ticket.base_price)
							_ticket.paymentLink = paymentLink
						}
					})
					setTicketStatus(res)
				} else {
					setShowError(true)
					errorTimer()
				}
			})
		} catch (error) {
			console.log("🚀 ~ file: Dashboard.js:28 ~ handleGenrateReport ~ error:", error)
		}
	}

	const closeTicket = () => {
		setTicketStatus(undefined)
	}

	const errorTimer = () => setTimeout(() => {
		setShowError(false)
	}, 4000)

	return (
		<Box mx={4}>
			{!isLoggedIn && (
				<Login onLogin={handleLogin} />
			)}
			{isLoggedIn && (
				<>
					<PingResponseBar />
					<Stack direction={'row'} mt={2}>
						<Button colorScheme='green' variant='outline' onClick={() => showLastTickets(10)}>
							Ver últimos 10 tickets
						</Button>
					</Stack>
					<Flex direction="row" justifyContent="space-between" gap={4}></Flex>
					<Box
						as="form"
						onSubmit={(e) => {
								e.preventDefault()
						}}
						p={8}
						borderWidth={1}
						borderRadius={8}
						boxShadow="md"
						display="flex"
						flexDirection="column"
						width="100%"
						mt={2}
					>
						<Flex direction="row" mb={4}>
							<Box flex="1" mr={4} mb={0}>
								<Text fontSize="lg" mb={2}>
									Nº de ticket
								</Text>
								<Input
									type="text"
									placeholder="Id de factura"
									value={ticket || ''}
									onChange={(e) => setTicket(e.target.value)}
									mb={4}
								/>
							</Box>
						</Flex>
						<Button
							mt={0} colorScheme="gray"
							type="submit"
							fontSize="lg"
							onClick={handleGenrateReport}
						>
							Ver estado
						</Button>
					</Box>
					{showError && (
						<Flex bg={"rgba(255, 0, 0, 0.3)"} p={2} mt={2} justifyContent="center">
							<Text color="white">{`El Nº de ticket ${ticket} no existe en el sistema. Inserte un Nº de ticket correcto.`}</Text>
						</Flex>
					)}
					{ticketStatus && ticketStatus !== false && ticketStatus.map((tStatus, idx) => (
						<Box
						p={8}
						my={8}
						borderWidth={1}
						borderRadius={8}
						boxShadow="md"
						display="flex"
						flexDirection="column"
						width="100%"
						key={idx}
					>
						{tStatus.original_data?.createdAt && (
							<Text fontSize="lg" mb={2}>
								Fecha: {new Date(tStatus.original_data?.createdAt).toLocaleString()}
							</Text>
						)}
						<Text fontSize="lg" mb={2}>
							Num ticket: {tStatus.num_ticket}
						</Text>
						<Text fontSize="lg" mb={2}>
							Pago recibido: {tStatus.paymentReceived ? 'Sí' : 'No'}
							<Icon
								as={tStatus.paymentReceived ? CheckCircleIcon : CloseIcon}
								color={tStatus.paymentReceived ? 'green.500' : 'red.500'}
								ml={2}
							/>
						</Text>
						<Text fontSize="lg" mb={2}>
							Estado: {tStatus.status}
						</Text>
						<Text fontSize="lg" mb={2}>
							Precio base: {tStatus.base_price}
						</Text>
						<Text fontSize="lg" mb={2}>
							Propina: {tStatus.tip}
						</Text>
						<Text fontSize="lg" mb={2}>
							Comisión: {tStatus.comision}
						</Text>
						<Text fontSize="lg" mb={2}>
							Total en euros: {tStatus.amount_eur}
						</Text>
						<Text fontSize="lg" mb={2}>
							Precio en crypto: {tStatus.amount_cryto} {tStatus.crypto}
						</Text>
						<Stack direction={'row'} alignContent={'center'} mt={4} justifyContent={'space-around'}>
							<Button
								colorScheme="gray"
								type="submit"
								fontSize="lg"
								onClick={closeTicket}
							>
								Cerrar
							</Button>
							<Button colorScheme="green" onClick={() => onSelectTicket(tStatus)}>Imprimir ticket</Button>
						</Stack>
					</Box>
					))}
					<Modal isOpen={isOpen} onClose={onClose}>
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>
								¿Deseas imprimir el ticket {selectedStatus?.num_ticket}?
							</ModalHeader>
							<ModalCloseButton />
							{!selectedStatus?.paymentReceived && selectedStatus?.paymentLink && (
								<ModalBody display={'flex'} alignItems={'center'} justifyContent={'center'}>
									<QRCode
										style={{ height: "auto", maxWidth: "50%", width: "50%" }}
										value={selectedStatus?.paymentLink?.url}
									/>
								</ModalBody>
							)}
							<ModalFooter justifyContent={'space-around'}>
								<Button variant='ghost' onClick={onCancelPrint}>Cancelar</Button>
								<Button colorScheme='green' mr={3} onClick={() => onHandlePrint()}>
									Imprimir ticket
								</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>
					{selectedStatus && (
						<div style={{display: 'none'}}>
							<PrintComponent
								ref={componentRef}
								ticketNumber={selectedStatus.num_ticket}
								paymentReceived={selectedStatus.paymentReceived}
								status={selectedStatus.status}
								base_price={selectedStatus.base_price}
								amount_eur={selectedStatus.amount_eur}
								amount_cryto={selectedStatus.amount_cryto}
								crypto={selectedStatus.crypto}
								tip={selectedStatus.tip}
								comision={selectedStatus.comision}
								paymentLink={selectedStatus?.paymentLink}
							/>
						</div>
					)}
				</>
			)}
		</Box>
	)
})

export default Dashboard
