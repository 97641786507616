import React from 'react'
import QRCode from "react-qr-code";
import { Box, Text, Icon, Stack } from '@chakra-ui/react'
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons'


const PrintComponent = React.forwardRef((props, ref) => {
  return (
    <Box ref={ref} w={'100%'} p={2} border={'1px'} borderColor={'blackAlpha.200'} borderRadius={8}>
      <Stack direction={'row'}>
        <Text fontSize={'6xl'}>
          Nº Ticket:
        </Text>
        <Text fontSize={'6xl'} ml={2}>
          {props.ticketNumber}
        </Text>
      </Stack>
      <Box
        p={8}
        my={8}
        display="flex"
        flexDirection="column"
        width="100%"
        mb={40}
      >
        <Box textAlign={'center'}>
          <Icon
            style={{ height: "auto", maxWidth: "50%", width: "50%" }}
            as={props.paymentReceived ? CheckCircleIcon : CloseIcon}
            color={props.paymentReceived ? 'green.500' : 'red.500'}
          />
        </Box>
        <Text fontSize="6xl" mb={2} textAlign={'center'}>
          {props.paymentReceived ? 'PAGADO' : 'NO PAGADO'}
        </Text>
        <Text fontSize="6xl" mb={2}>
          Estado: {props.status}
        </Text>
        <Text fontSize="6xl" mb={2}>
          Precio base: {props.base_price}
        </Text>
        <Text fontSize="6xl" mb={2}>
          Propina: {props.tip}
        </Text>
        <Text fontSize="6xl" mb={2}>
          Comisión: {props.comision}
        </Text>
        <Text fontSize="6xl" mb={2}>
          Total en euros: {props.amount_eur}
        </Text>
        <Text fontSize="6xl" mb={2}>
          Precio en crypto: {props.amount_cryto} {props.crypto}
        </Text>
        {!props.paymentReceived && props.paymentLink && (
          <QRCode
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={props.paymentLink?.url}
          />
        )}
      </Box>
      <Box w={'100%'} h={'3px'} bgColor={'black'}/>
    </Box>
  );
});
export default PrintComponent