import axios from "axios";

// const API_URL = "http://localhost:3333";
const API_URL = "https://nassau-api.981block.com/"

const api = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
		"api-key": "E1UbDxj3m5PXc0dmS6erkR8cSGMQiBWYyBgXqh0quB",
	},
});

export const getStatusByTicket = async (ticket) => {
	try {
		const request = await api.get(`/status/${ticket}`);
		return request.data.response.body;
	} catch (error) {
		console.error("🚀 ~ file: paymentCalls.js:6 ~ error", error);
	}
};
export const getLastTicketsStatus = async (num) => {
	try {
		const request = await api.get(`/invoices/last/${num}`);
		return request.data.response.body.data;
	} catch (error) {
		console.error("🚀 ~ file: paymentCalls.js:6 ~ error", error);
	}
};
export const getPaymentLink = async (title, amount) => {
	try {
		const request = await api.post("/payment", {title, amount});
		return request.data.response.body;
	} catch (error) {
		console.log("🚀 ~ file: paymentCalls.js:34 ~ getPaymentLink ~ error:", error)
	}
}
