import { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'

const AppRoutes = memo(() => {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
        </Routes>
    )
})


export default AppRoutes