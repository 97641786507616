import { Box, Button, Flex, Input, Text } from "@chakra-ui/react"
import { useState } from "react"

const STATIC_USERNAME = 'nassauAdmin'
const STATIC_PASSWORD = 'VivaIbiza123..'

const Login = ({onLogin}) => {
	const [user, setUser] = useState(null)
	const [password, setPassword] = useState(null)
	const [error, setError] = useState(null)

	const handleLogin = () => {
		if (user === STATIC_USERNAME && password === STATIC_PASSWORD) {
			onLogin(true);  // Llama a la función de retorno con true si el inicio de sesión es exitoso
		} else {
			setError("Usuario o contraseña incorrectos")
		}
	}

	return (
		<Box
			as="form"
			onSubmit={(e) => {
				e.preventDefault()
				handleLogin()
			}}
			p={8}
			borderWidth={1}
			borderRadius={8}
			boxShadow="md"
			display="flex"
			flexDirection="column"
			width="100%"
		>
			<Flex direction="row" mb={4}>
				<Box flex="1" mr={4} mb={0}>
					<Text fontSize="lg" mb={2}>
						Usuario:
					</Text>
					<Input
						type="text"
						value={user || ''}
						onChange={(e) => setUser(e.target.value)}
						mb={4}
					/>
				</Box>
				<Box flex="1" mb={0}>
					<Text fontSize="lg" mb={2}>
						Contraseña:
					</Text>
					<Input
						type="password"
						value={password || ''}
						onChange={(e) => setPassword(e.target.value)}
						mb={4}
					/>
				</Box>
			</Flex>
			<Button mt={0} colorScheme="gray" type="submit" fontSize="lg">
				Login
			</Button>

			{error && (
				<Text 
					color="red.500" 
					mt={4}
					textAlign="center"
					pt={4}
				>
					{error}
				</Text>
			)}
		</Box>
	)
}

export default Login